import { environement } from '@environments/environment';

export const MAX_QUESTION_DURATION = 60; // seconds (60 seconds)
export const DEFAULT_DURATION = 10; // seconds (10 seconds)
export const DEFAULT_LOW_DURATION = 3; // seconds (3 seconds)
export const DEFAULT_DURATION_MS = 1000; // milliseconds (1 seconds)
export const DEFAULT_EXPLANATION = 60_000; // milliseconds (1 seconds)
export const DEFAULT_MAX_FILE_SIZE = 10_000_000;
export const DEFAULT_CANDIDATE_POLLING = 4_000;
export const DEFAULT_REDIRECTION_TIMEOUT = 4_000;
export const DEFAULT_WAITING_PAGE_TIMEOUT = 3_000;
export const DEFAULT_DATE_LOCAL = 'fr-CA';
export const DEFAULT_DATE_FORMAT = 'dd/mm/yy';
export const MONTH_NAMES = [
  'janvier',
  'février',
  'mars',
  'avril',
  'mai',
  'juin',
  'juillet',
  'août',
  'septembre',
  'octobre',
  'novembre',
  'décembre',
];
export const LIMIT_ROW_TABLE = 10;
export const IS_PRODUCTION = environement.ENV !== 'development';
export const DEFAULT_QUESTION_PREVIEW_INDEX = -1;
